.bg-image {
    background-image: url('../images/background.webp');
    background-size: cover;
    background-position: center;
  }

.h1-grips {
    color: #144378;
    font-weight: bold;
}

.h2-grips {
    color: #144378;
    font-weight: bold;
}

.h4-grips {
    color: #144378;
    font-weight: 400;
    font-size: medium;
}

.text-grips {
    color: #144378;
}

.grips-ol li::marker {
    color: #df3452;
    font-size: 1.5em;
  }

.grips-margin-left {
    margin-left: 5vw !important;
}

.grips-bg {
    background-color: #144378;
}

.timeline {
    list-style-type: none;
    position: relative;
  }
  
  .timeline::before {
    content: '';
    background: #144378;
    display: inline-block;
    position: absolute;
    width: 0.2em;
    height: 100%;
    left: 50%;
    top: 0;
    margin-left: -0.1em;
  }
  
  .timeline li {
    padding: 1em 0;
    position: relative;
  }
  
  .timeline li::before {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    border-top: 1px dotted #144378;
  }
  
  .timeline-left li::before {
    right: 50%;
  }
  
  .timeline-right li::before {
    left: 50%;
  }
  
  .timeline-left {
    text-align: right;
    padding-right: 30px;
  }
  
  .timeline-right {
    text-align: left;
    padding-left: 30px;
  }
  
  .timeline li::after {
    content: '';
    background: url('../images/regestrieren-grips.svg') no-repeat;
    display: inline-block;
    position: absolute;
    width: 2em;
    height: 2em;
    top: 0;
  }
  
  .timeline-left li::after {
    right: calc(50% - 1em);
  }
  
  .timeline-right li::after {
    left: calc(50% - 1em);
  }
  .timeline h4 {
    position: relative;
    top: 2em;
  }
  
  .timeline p {
    margin-top: 0.5em;
  }
  
  .timeline .time {
    font-size: 0.8em;
    color: #999;
  }

  div {
    pointer-events: auto;
  }


  .text-responsive {
    font-size: 10px; /* Default font size for mobile */
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .text-responsive {
      font-size: 10px;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .text-responsive {
      font-size: 12px;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .text-responsive {
      font-size: 16px;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .text-responsive {
      font-size: 20px;
    }
  }



  .h1-responsive {
    font-size: 17px; /* Default font size for mobile */
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .h1-responsive {
      font-size: 21px;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .h1-responsive {
      font-size: 23px;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .h1-responsive {
      font-size: 30px;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .h1-responsive {
      font-size: 43px;
    }
  }


  .h2-responsive {
    font-size: 17px; /* Default font size for mobile */
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .h2-responsive {
      font-size: 19px;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .h2-responsive {
      font-size: 22px;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .h2-responsive {
      font-size: 26px;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .h2-responsive {
      font-size: 30px;
    }
  }