.bg-image {
    background-image: url('../images/background.webp');
    background-size: cover;
    background-position: center;
  }

  .custom-height {
    height: 80vh;
  }

  .rectangle {
    position: relative;
    width: 100%; /* Adjust as needed */
    height: 100%; /* Adjust as needed */
    overflow: hidden;
  }
  
  .rectangle::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #144378;
    transform: skew(-13deg);
    transform-origin: 100% 0;
  }

  .text-container {
    position: relative;
    transform: skew(10deg);
    color: white;
  }

  .div-block-4 {
    position: relative;
    z-index: 1;
    width: 90vw;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}

h1{
    font-size: 3.5rem;
    font-weight: 700;
}

.image-container {
    position: absolute;
    right: 0;
    z-index: 1; /* This will put the image in the foreground */
  }


.image-2 {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    max-width: 90%;
    min-width: 1150px;
    margin-top: 40px;
    margin-left: 0px;
}

@media (max-width: 1023px) {
    .image-2-1 {
      display: none;
    }
  }

  .grips-margin {
    margin-left: 10vw;
  }

.section2-margin {
  margin-top: 10vh;
  margin-bottom: 10vh;
  margin-left: 3vw;
  margin-right: 3vw;
}

.section2-padding {
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 6vw;
  padding-right: 6vw;
}

.white-ul {
  color: white;
}