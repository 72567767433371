.border-right {
    border-right: 2px solid #144378;
    height: 100%; /* Take the full height of the parent element */
}

body {
    min-height: 100vh;
}

.custom-link {
    font-family: 'Arial', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #144378 !important; 
}

.language-selector {
    font-family: 'Arial', sans-serif;
    font-size: 18px;
    color: #144378 !important; 
}

.outlined-dropdown-item {
    border: 1px solid #144378; /* Change the color as needed */
}

.font-weight-bold {
    font-weight: bold;
}

.dropdown-items{
    font-family: 'Arial', sans-serif;
    color: #144378 !important; 
}

@media (max-width: 412px) {
    .navbar-brand {
        margin-right: 0px !important;
        width: 250px !important;
    }
}

.h4-timeline{
    color: #144378;
    font-weight: bold;
    font-size: large;
}